.chat-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #C766EF 0%, #7928D2 51%, #2B0C52 100%);

  .chat-input {
    display: flex;
    border-radius: 15px;
    align-items: center;
    padding: 10px;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid white;

    img {
      width: 40px;
    }
  }

  .chat-input-field {
    flex-grow: 1;
    padding: 10px 15px;
    border: none;
    outline: none;
    font-size: 16px;
    color: white;
    background: transparent;
  }

  .send-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding-left: 12px;
    display: flex;
    align-items: center;
  }

  .send-button-gray {
    color: gray;
  }

  .send-button:hover {
    img {
      color: #ccc;
    }
  }
}
