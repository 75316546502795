.header {
 background-color: #ffffff;
 color: white;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 padding: 10px 0;
}

.container {
 display: flex;
 align-items: center;
 justify-content: space-between;
 height: 100%;
}

.logo {
 font-size: 1.5rem;
 text-decoration: none;
 color: white;
 padding-right: 20px;
}

.nav {
 display: flex;
 gap: 30px;
}

.nav {
 display: flex;
 gap: 20px;
}

.navButton {
 color: #ffffff;
 text-decoration: #260046;
 padding: 10px 15px;
 transition: color 0.3s, background-color 0.3s;
 background-color: #260046;
 border: none;
 display: flex;
 align-items: center;
}

.navButton:hover {
 color: #ffffff;
 background-color: #260046;
 text-decoration: none;
}