.treeRoot {
    padding-left: 10px;
    /* Adjust as needed */
}

.treeItem {
    display: flex;
    align-items: center;
    padding: 10px;
}

.treeLabel {
    flex: 1;
}